<template>
    <Preloader v-if="isLoading" />
    <router-view v-slot="{ Component }">
        <transition
            name="page-opacity"
            mode="out-in"
        >
            <component :is="Component" />
        </transition>
    </router-view>
    <BackToTop />
</template>
<script>
import Preloader from "./components/Layouts/Preloader";
import BackToTop from "./components/Layouts/BackToTop";
import 'vue3-carousel/dist/carousel.css'
export default {
    name: "App",
    components: {
        Preloader,
        BackToTop,
    },
    data() {
        return {
            isLoading: true,
        };
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 2000);
    },
};
</script>
<style lang="scss">
.page-opacity-enter-active,
.page-opacity-leave-active {
    transition: 600ms ease all;
}
.page-opacity-enter-from,
.page-opacity-leave-to {
    opacity: 0;
}
</style>




