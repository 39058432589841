<template>
    <div>
        <!-- <TopHeader /> -->
        <MainNavbar />
        <SidebarModal />
        <PageBanner pageTitle="Services Details" backgroundImage="https://www.shutterstock.com/image-photo/software-engineer-using-digital-tablet-260nw-2387591191.jpg"  />
        <ServicesDetails />
        <MainFooter />
    </div>
</template>

<script>
// import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
import PageBanner from '../Common/PageBanner'
import ServicesDetails from '../ServicesDetails/ServicesDetails'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'ServicesDetailsPage',
    components: {
        // TopHeader,
        MainNavbar,
        SidebarModal,
        PageBanner,
        ServicesDetails,
        MainFooter,
    }
}
</script>