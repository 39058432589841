import { createWebHistory, createRouter } from "vue-router";

import HomeOnePage from "../components/Pages/HomeOnePage";
import HomeTwoPage from "../components/Pages/HomeTwoPage";
import HomeThreePage from "../components/Pages/HomeThreePage";
import AboutOnePage from "../components/Pages/AboutOnePage";
import AboutTwoPage from "../components/Pages/AboutTwoPage";
import PricingPage from "../components/Pages/PricingPage";
import TeamPage from "../components/Pages/TeamPage";
import FaqPage from "../components/Pages/FaqPage";
import TestimonialPage from "../components/Pages/TestimonialPage";
import LogInPage from "../components/Pages/LogInPage";
import RegisterPage from "../components/Pages/RegisterPage";
import ComingSoonPage from "../components/Pages/ComingSoonPage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import TermsConditionsPage from "../components/Pages/TermsConditionsPage";
import ErrorPage from "../components/Pages/ErrorPage";
import ServicesPage from "../components/Pages/ServicesPage";
import ServicesDetailsPage from "../components/Pages/ServicesDetailsPage";
import CaseStudyPage from "../components/Pages/CaseStudyPage";
import CaseStudyDetailsPage from "../components/Pages/CaseStudyDetailsPage";
import PortfolioDetailsPage from "../components/Pages/PortfolioDetailsPage"
import BlogPage from "../components/Pages/BlogPage";
import BlogDetailsPage from "../components/Pages/BlogDetailsPage";
import ContactPage from "../components/Pages/ContactPage";

const routes = [
  { path: "/", name: "HomeOnePage", component: HomeOnePage },
  { path: "/home-demo-2", name: "HomeTwoPage", component: HomeTwoPage },
  { path: "/home-demo-3", name: "HomeThreePage", component: HomeThreePage },
  { path: "/about", name: "AboutOnePage", component: AboutOnePage },
  { path: "/about-2", name: "AboutTwoPage", component: AboutTwoPage },
  { path: "/pricing", name: "PricingPage", component: PricingPage },
  { path: "/team", name: "TeamPage", component: TeamPage },
  { path: "/faq", name: "FaqPage", component: FaqPage },
  {
    path: "/testimonials",
    name: "TestimonialPage",
    component: TestimonialPage,
  },
  { path: "/login", name: "LogInPage", component: LogInPage },
  { path: "/singup", name: "RegisterPage", component: RegisterPage },
  { path: "/coming-soon", name: "ComingSoonPage", component: ComingSoonPage },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/terms-conditions",
    name: "TermsConditionsPage",
    component: TermsConditionsPage,
  },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
  { path: "/services", name: "ServicesPage",  component: ServicesPage },
  {
    path: "/service-details",
    name: "ServicesDetailsPage",
    component: ServicesDetailsPage,
  },
  { path: "/case-study", name: "CaseStudyPage", component: CaseStudyPage },
  {
    path: "/case-study-details",
    name: "CaseStudyDetailsPage",
    component: CaseStudyDetailsPage,
  },
  {
    path: "/portfolio-details",
    name: "PortfolioDetailsPage",
    component: PortfolioDetailsPage,
  },
  { path: "/blog", name: "BlogPage", component: BlogPage },
  {
    path: "/blog-details",
    name: "BlogDetailsPage",
    component: BlogDetailsPage,
  },
  { path: "/contact", name: "ContactPage", component: ContactPage },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
