<template>
    <div>
        <!-- <TopHeader /> -->
        <MainNavbar />
        <SidebarModal />
        <PageBanner pageTitle="Our Blog" backgroundImage="https://img.freepik.com/free-photo/toy-bricks-table_144627-48267.jpg?t=st=1727373471~exp=1727377071~hmac=c999dc652421b37f8092aa94cd66ea7387d99f1e812e8a1fbe38ac8586fc4964&w=1060"/>
        <BlogContent />
        <MainFooter />
    </div>
</template>

<script>
// import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
import PageBanner from '../Common/PageBanner'
import BlogContent from '../Blog/BlogContent'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'BlogPage',
    components: {
        // TopHeader,
        MainNavbar,
        SidebarModal,
        PageBanner,
        BlogContent,
        MainFooter,
    }
}
</script>