<template>
  <div class="case-details-area ptb-100">
    <div class="container text-center" >
      <h2>PORTFOLIO DETAILS</h2>
      <h3>{{ portfolioDetail.title }}</h3>
      <p>{{ portfolioDetail.about }}</p>
    </div>

    <div class="carousel-wrapper">
      <Carousel
        :items-to-show="3"
        :wrap-around="true"
        :autoplay="3000"
        :transition="500"
        :breakpoints="breakpoints"
      >
        <Slide v-for="(item, index) in carouselItems" :key="index">
          <div class="carousel-slide">
            <img :src="item.image" alt="Slide Image" />
            <p>{{ item.title }}</p>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import { portfolio } from "../../constants/portfolio";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  created() {
    this.getParam();
  },
  methods: {
    getParam() {
      const { service } = this.$route.query;
      this.portfolioDetail = service
        ? portfolio[service?.toLocaleLowerCase()]
        : portfolio["drive-assist"];
      this.carouselItems = service ? portfolio[service]?.images : [];
    },
  },
  data() {
    return {
      portfolioDetail: null,
      carouselItems: [
        { image: 'https://via.placeholder.com/300x200', text: 'Slide 1' },
        { image: 'https://via.placeholder.com/300x200', text: 'Slide 2' },
        { image: 'https://via.placeholder.com/300x200', text: 'Slide 3' },
        { image: 'https://via.placeholder.com/300x200', text: 'Slide 4' },
        { image: 'https://via.placeholder.com/300x200', text: 'Slide 5' },
      ],
      breakpoints: {
        1024: {
          itemsToShow: 2,
          snapAlign: 'center',
        },
        768: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
      },
    };
  },
};
</script>

<style scoped>
.carousel-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.carousel-slide {
  padding: 10px;
}

.carousel-slide img {
  width: 100%;
  height: auto;
}

.carousel-slide p {
  margin-top: 10px;
  text-align: center;
}
</style>
