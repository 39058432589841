<template>
    <div>
        <!-- <TopHeader /> -->
        <MainNavbar />
        <SidebarModal />
        <PageBanner pageTitle="Portfolio Details" backgroundImage="https://img.freepik.com/free-photo/flat-lay-workstation-with-copy-space-laptop_23-2148430879.jpg" />
        <PortfolioDetails />
        <MainFooter />
    </div>
</template>

<script>
// import TopHeader from '../Layouts/TopHeader'

import MainNavbar from '../Layouts/MainNavbar'
import SidebarModal from '../Layouts/SidebarModal'
import PageBanner from '../Common/PageBanner'
import PortfolioDetails from '../PortfolioDetails/PortfolioDetails'
import MainFooter from '../Layouts/MainFooter'

export default {
    name: 'PortfolioDetailsPage',
    components: {
        // TopHeader,
        MainNavbar,
        SidebarModal,
        PageBanner,
        PortfolioDetails,
        MainFooter,
    }
}
</script>