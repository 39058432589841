<template>
    <div class="banner-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="banner-content">
                        <span>Empowering Business with Technology</span>
                        <h1>Fueling Innovation
                            Accelerating Your Success.</h1>
                        <p class="banner-p">At Upraiss, we don’t just develop solutions – we create the future. We empower businesses to break boundaries and lead their industries with cutting-edge technology. From bespoke web and app development to the next wave of AI and Blockchain, we’re here to turn your vision into a reality that stands the test of time.
                        <br/>Join us on the journey to innovation and growth <br/>welcome to Upraiss.
                        </p>

                        <div class="banner-input">
                            <form @submit="handleSubmit" class="banner-form d-flex">
                                <!-- <input type="text" class="form-control" placeholder="Your Email Address"
                                    v-model="email"> -->
                                <a  href="https://calendly.com/indrapandey-upraiss" target="_blank" class="default-btn">Book Apointment</a>
                                <!-- <button type="submit" class="default-btn"></button> -->
                            </form>
                            <p v-if="emailError" class="error-message">Please enter correct email</p>
                        </div>
                        <p class="link-p">We’ll contact back in 24h
                            <router-link to="/contact">Get Free Consultation!
                                <img src="../../assets/images/svgs/main-icon.svg" alt="svg">
                            </router-link>
                        </p>
                    </div>
                </div>
                <div class="col-lg-7" data-aos="fade-up" data-aos-duration="1000">
                    <div class="banner-image">
                        <img src="../../assets/images/banner/banner-shape1.webp" alt="image">
                    </div>
                </div>
            </div>
            <div class="banner-shape">
                <img src="../../assets/images/shapes/banner-shape1.webp" class="banner-shape-1" alt="image">
                <img src="../../assets/images/shapes/banner-shape2.webp" class="banner-shape-2" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            email: '',
            emailError: false,
        };
    },
    methods: {
        handleSubmit(event) {
            console.log("in the handle");
            event.preventDefault(); // Prevent form submission
            this.emailError = false;
            // Perform form validation

            if (!this.email || !this.isValidEmail(this.email)) {
                this.emailError = true;
            }


            // Proceed with API request if form is valid
            if (!this.nameError && !this.emailError && !this.messageError) {
                const data = {
                    email: this.email,
                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                axios.post('https://node-Upraiss.onrender.com/v0/sendEmailNotification', data, config)
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                    });

                // Reset form fields
                this.email = '';
            }
        },
        isValidEmail(email) {
            // Basic email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

    },
    name: 'MainBanner'
}
</script>

<style lang="scss">
.error-message {
    color: red;
    font-size: 12px;
}

.banner-area {
    position: relative;
    z-index: 1;
    padding-top: 120px;
    padding-bottom: 120px;

    .container-fluid {
        padding-right: 30px;
        max-width: 100%;
        padding-left: 150px !important;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: #F4F8FC;
        z-index: -1;
    }

    .banner-shape-1 {
        position: absolute;
        top: 14%;
        left: 36%;
        -webkit-animation: moveleftbounce 8s linear infinite;
        animation: moveleftbounce 8s linear infinite;
    }

    .banner-shape-2 {
        position: absolute;
        top: -10px;
        z-index: -1;
        left: 16%;
    }
}

.banner-content {
    span {
        color: var(--hoverColor);
        display: inline-block;
        margin-bottom: -5px;
        font-weight: 500;
    }

    h1 {
        font-weight: 900;
        font-size: 75px;
        line-height: 90px;
        margin-bottom: 20px;
    }

    .banner-p {
        margin-bottom: 45px;
        margin-right: -15px;
    }

    .banner-input {
        margin-bottom: 18px;

        .form-control {
            width: 66%;
            margin-right: 20px;
            background: #FFFFFF;
            border-radius: 5px;
            border: none;
            color: var(--bodyColor);
            padding-left: 20px;
            transition: var(--transition);

            &:focus {
                box-shadow: unset;

                &::placeholder {
                    color: transparent;
                    transition: var(--transition);
                }
            }

            &::placeholder {
                color: #888888;
            }
        }
    }

    .link-p {
        a {
            color: var(--mainColor);
            font-weight: 600;

            &:hover {
                color: var(--hoverColor);

                img {
                    filter: brightness(0) saturate(100%) invert(52%) sepia(39%) saturate(6078%) hue-rotate(327deg) brightness(96%) contrast(128%);
                }
            }

            img {
                transition: var(--transition);
            }
        }
    }
}
</style>