export const portfolio = {
    "drive-assist": {
        title: "Drive Assist",
        about:
            "dfd dis competitive world and industry, Khwaishh is a multi-dimensional e-commerce platform which thrives as a vibrant springboard for young designers and homegrown labels.",
        listOffering: [
            { id: 1, li: "Tech Stack - Woo Commerce, AWS Hosting" },
            { id: 2, li: "Key Deliverables - UI/UX, Web Development, Admin Panel, Marketing Plugin Integration, Maintainence" },
            { id: 3, li: "Key Features - Multi Vendor, Product Search and Recommendation system, Checkout Process, Payment Gateway, Product share on Social Platforms, Order Tracking" },
        ],
        images: [{
            title: "Splash Screen",
            image: require("../assets/images/portfolio/app/driveassist/Splash.png"),
        }
        ],
    },

    "post-money":{
        title: "Post Money",
        about:
            "dfd dis competitive world and industry, Khwaishh is a multi-dimensional e-commerce platform which thrives as a vibrant springboard for young designers and homegrown labels.",
        listOffering: [
            { id: 1, li: "Tech Stack - Woo Commerce, AWS Hosting" },
            { id: 2, li: "Key Deliverables - UI/UX, Web Development, Admin Panel, Marketing Plugin Integration, Maintainence" },
            { id: 3, li: "Key Features - Multi Vendor, Product Search and Recommendation system, Checkout Process, Payment Gateway, Product share on Social Platforms, Order Tracking" },
        ],
        images: [{
            title: "Home Screen",
            image: require("../assets/images/portfolio/app/PostMoney/PM.png"),
        }
        ],
    },
    "routes":{
        title: "Routes",
        about:
            "dfd dis competitive world and industry, Khwaishh is a multi-dimensional e-commerce platform which thrives as a vibrant springboard for young designers and homegrown labels.",
        listOffering: [
            { id: 1, li: "Tech Stack - Woo Commerce, AWS Hosting" },
            { id: 2, li: "Key Deliverables - UI/UX, Web Development, Admin Panel, Marketing Plugin Integration, Maintainence" },
            { id: 3, li: "Key Features - Multi Vendor, Product Search and Recommendation system, Checkout Process, Payment Gateway, Product share on Social Platforms, Order Tracking" },
        ],
        images: [{
            title: "Home Screen",
            image: require("../assets/images/portfolio/app/Routes/Routes111.png"),
        }
        ],
    },
    "bit-memoir":{
        title: "BitMemoir",
        about:
            "dfd dis competitive world and industry, Khwaishh is a multi-dimensional e-commerce platform which thrives as a vibrant springboard for young designers and homegrown labels.",
        listOffering: [
            { id: 1, li: "Tech Stack - Woo Commerce, AWS Hosting" },
            { id: 2, li: "Key Deliverables - UI/UX, Web Development, Admin Panel, Marketing Plugin Integration, Maintainence" },
            { id: 3, li: "Key Features - Multi Vendor, Product Search and Recommendation system, Checkout Process, Payment Gateway, Product share on Social Platforms, Order Tracking" },
        ],
        images: [{
            title: "Splash Screen",
            image: require("../assets/images/projects/project2.webp"),
        }
        ],
    },
    "east-mojo":{
        title: "EastMojo",
        about:
            "dfd dis competitive world and industry, Khwaishh is a multi-dimensional e-commerce platform which thrives as a vibrant springboard for young designers and homegrown labels.",
        listOffering: [
            { id: 1, li: "Tech Stack - Woo Commerce, AWS Hosting" },
            { id: 2, li: "Key Deliverables - UI/UX, Web Development, Admin Panel, Marketing Plugin Integration, Maintainence" },
            { id: 3, li: "Key Features - Multi Vendor, Product Search and Recommendation system, Checkout Process, Payment Gateway, Product share on Social Platforms, Order Tracking" },
        ],
        images: [{
            title: "Splash Screen",
            image: require("../assets/images/projects/project3.webp"),
        }
        ],
    },
    "grow-pital":{
        title: "Growpital",
        about:
            "dfd dis competitive world and industry, Khwaishh is a multi-dimensional e-commerce platform which thrives as a vibrant springboard for young designers and homegrown labels.",
        listOffering: [
            { id: 1, li: "Tech Stack - Woo Commerce, AWS Hosting" },
            { id: 2, li: "Key Deliverables - UI/UX, Web Development, Admin Panel, Marketing Plugin Integration, Maintainence" },
            { id: 3, li: "Key Features - Multi Vendor, Product Search and Recommendation system, Checkout Process, Payment Gateway, Product share on Social Platforms, Order Tracking" },
        ],
        images: [{
            title: "Splash Screen",
            image: require("../assets/images/projects/project1.webp"),
        }
        ],
    },
    "itp-media":{
        title: "ITP Media",
        about:
            "dfd dis competitive world and industry, Khwaishh is a multi-dimensional e-commerce platform which thrives as a vibrant springboard for young designers and homegrown labels.",
        listOffering: [
            { id: 1, li: "Tech Stack - Woo Commerce, AWS Hosting" },
            { id: 2, li: "Key Deliverables - UI/UX, Web Development, Admin Panel, Marketing Plugin Integration, Maintainence" },
            { id: 3, li: "Key Features - Multi Vendor, Product Search and Recommendation system, Checkout Process, Payment Gateway, Product share on Social Platforms, Order Tracking" },
        ],
        images: [{
            title: "Splash Screen",
            image: require("../assets/images/projects/project6.webp"),
        }
        ],
    },
    "brain-woo":{
        title: "BrainWoo",
        about:
            "dfd dis competitive world and industry, Khwaishh is a multi-dimensional e-commerce platform which thrives as a vibrant springboard for young designers and homegrown labels.",
        listOffering: [
            { id: 1, li: "Tech Stack - Woo Commerce, AWS Hosting" },
            { id: 2, li: "Key Deliverables - UI/UX, Web Development, Admin Panel, Marketing Plugin Integration, Maintainence" },
            { id: 3, li: "Key Features - Multi Vendor, Product Search and Recommendation system, Checkout Process, Payment Gateway, Product share on Social Platforms, Order Tracking" },
        ],
        images: [{
            title: "Splash Screen",
            image: require("../assets/images/projects/project5.webp"),
        }
        ],
    },
    "khawishh":{
        title: "Khawishh",
        about:
            "dfd dis competitive world and industry, Khwaishh is a multi-dimensional e-commerce platform which thrives as a vibrant springboard for young designers and homegrown labels.",
        listOffering: [
            { id: 1, li: "Tech Stack - Woo Commerce, AWS Hosting" },
            { id: 2, li: "Key Deliverables - UI/UX, Web Development, Admin Panel, Marketing Plugin Integration, Maintainence" },
            { id: 3, li: "Key Features - Multi Vendor, Product Search and Recommendation system, Checkout Process, Payment Gateway, Product share on Social Platforms, Order Tracking" },
        ],
        images: [{
            title: "Splash Screen",
            image: require("../assets/images/projects/project4.webp"),
        }
        ],
    }
}